* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

#root {
  height: 100%;
}

body {
  font-family: 'Oswald', sans-serif;
  font-size: 16px;
}

p {
  line-height: 1.6;
  font-size: 0.9em;
}

form {
  font-family: 'Open Sans', sans-serif;
  font-size: 0.8rem;
  margin-bottom: 4vh;
}

#bg {
  /* positioning video background */
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%) scale(1.03);
}

.bg-img {
  position: fixed;
  opacity: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  /* background-image: url('/img/background.jpg'); */
  background-repeat: no-repeat;
  background-size: auto 105vh;
  background-position: 69% bottom;
  overflow: visible;
  z-index: -100;
  transform: scale(1.03);
}

.bg-img:before{
  content: "";
  background: inherit; 

  /* position: absolute;
  left: -5vw;
  right: 0;
  top: -5vh; 
  bottom: 0;
  min-width: 110vw;
  min-height: 110vh; */

  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  /* fade */
  z-index: -99;
  transition: filter .4s;
}

.blur:before, .blur-video {
  filter: blur(5px) brightness(80%);
}

.bg-under {
  z-index: -101;
}

.transition-none {
  transition: none;
}

a {
  color: #F2F2F2;
  text-decoration: none;
  transition: color 0.1s;
}

a:hover {
  color: #E0E0E0;
  text-decoration: none;
}

textarea {
  height: auto;
}

/* react transition group styles */

.page-enter {
  opacity: 0;
}

.page-enter-done {
  opacity: 1;
  transition: opacity 300ms;
}

.page-exit {
  opacity: 1;
}
.page-exit-active {
  opacity: 0 !important;
  transition: opacity 200ms;
}

/* mobile navbar */
nav {
  /* background-color: rgba(196, 196, 196, 0.20); */
  /* background-color: rgba(240, 235, 224, 0); */
  background: linear-gradient(90deg, rgba(33,37,41,0.4) 0%, rgba(33,37,41,0) 100%);
  display: flex;
  align-items: center;

  position: fixed;
  left: 0;
  top: 0;
  width: 60%;
  height: 100%;
  padding-top: 40%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  
  z-index: 2;
  opacity: 1;
  transition: opacity 0.2s;
}

.nav-collapse {
  height: 36%;
  width: 100%;
  padding-bottom: 2vh;
}

.inactive {
  opacity: 0;
  z-index: -10;
}

ul {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding-left: 18vw;
}

li {
  list-style: none;
  font-size: 0.9em;
  margin-bottom: 4vh;
}

button {
  border: none;
  font-size: 0.8em;
}

button:focus {
  outline: none;
}

.nav-link {
  padding: 0;
  font-size: 1.2rem;
}

.desktop-only {
  display: none;
}

.bg-menu {
  position: fixed;
  width: 45vw;
  top: 0;
  right: 0;
  margin-top: 10%;
  margin-right: 3%;
}

.menu-toggler {
  position: fixed;
  top: 0;
  right: 0;
}

.bg-pos {
  width: 100%;
}

.bg-pos input {
  display: block;
  width: 100%;
  margin: 1vh auto;
}

.bg-pos p {
  width: 100%;
  color: #F2F2F2;
  text-align: center;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  margin: 0 10% 18% 10%;
}

#logo-image {
  display: block;
  width: 100%;
  margin: 0;
}

#logo-image>img {
  width: 100%;
}

.App {
  text-align: center;
}

.main {
  display: flex;
  flex-direction: column;
  padding: 0;
  height: 100%;
}

main {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: visible;
}

.active {
  color: #BFB4A2 !important;
}

.page-title {
  color: #F2F2F2;
  font-family: 'Oswald', sans-serif;
  font-size: 1.6rem;
  text-align: center;
  margin-top: 2vh;
}

.dark {
  color: #212529;
}

.title-hr {
  width: 71%;
  border: 2px solid #F2F2F2;
  margin: 0 auto 3vh auto;
}

.hr-dark {
  border: 2px solid #212529;
}

.hr-less-margin {
  width: 94.5%;
  margin: 0.5vh auto 0 auto;
}

/* FilmDetails */

.details-page {
  background-color: #212529;
  width: 100%;
  /* margin: 2vh auto 2vh auto; */
  opacity: 1;
  transition: opacity 0.3s;
}

.video-container {
  width: 100%;
  background-color: black;
}

.cover-image {
  width: 100%;
}

.details-content {
  padding: 2vh 4vw;
  /* font-family: 'PT Sans', 'Open Sans', sans-serif; */
  font-family: 'Open Sans', sans-serif;
  color: #F2F2F2;
  /* font-size: 14px; */
}

.details-margin {
  margin-bottom: 1.2rem;
}

.back-link {
  width: 100%;
  margin-top: 1vh;
  padding: 2vh 7vw 3vh 0;
  text-align: end;
  display: inline-block;
  background-color: rgba(184, 184, 184, 0.1);
  color: #F2F2F2;
}

.back-link-list {
  width: 90%;
  padding: 3vh 5vw 3vh 0;
  margin: 2vh auto 3vh auto;
  /* background: linear-gradient(0deg, rgba(33,37,41,1) 0%, rgba(33,37,41,0) 100%); */
  /* background: linear-gradient(0deg, rgba(33,37,41,1) 0%, rgba(33,37,41,0) 100%); */
  background-color: rgba(184, 184, 184, 0.2);
}

.back-link-contact {
  padding: 2vh 12vw 3vh 0;
}

.back-link-black {
  color: #212529;
  transition: color 0.2s;
}

.back-link-black:hover {
  color: rgb(97, 97, 97);
}

.title {
  font-family: 'Oswald', sans-serif;
  font-size: 2rem;
}

.year-duration {
  color: gray;
  margin-bottom: 1.6rem;
}

.year-duration-less-margin {
  margin-bottom: 0.5rem;
}

.colab-details {
  text-align: justify;
  margin-bottom: 1.6rem;
  width: 100%;
}

.details-section-header {
  font-family: 'Oswald', sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: 0.4rem;
}

.cast {
  text-align: start;
}

.crew-container, .summary-cast-container div {
  margin-bottom: 3vh;
}

.gallery {
  display: flex;
  flex-direction: column;
  /* margin-bottom: 4vh; */
}

.details-section {
  margin-bottom: 4vh;
}

.details-section:last-of-type{
  margin-bottom: 0vh;
}

/* .gallery-less-margin {
  margin-bottom: 2vh;
} */

.thumb-link {
  display: block;
  width: 100%;
  margin-bottom: 2vh;
}

/* .thumb-link:last-of-type {
  margin-bottom: 0;
} */

.thumb-img {
  width: 100%;
  height: auto;
}

.crew-item {
  /* margin-left: 0.5em; */
}

.crew-title {
  font-weight: 400;
  /* font-size: 1rem; */
  /* font-family: 'pt sans'; */
}

.crew p {
  text-align: justify;
}

.festivals-list {
  padding: 0;
}

.festivals-list-item {
  list-style-type: none;
  list-style-position: inside;
  margin-bottom: 0.3rem;
  text-align: start;
}

.review:first-of-type {
  margin-top: 3vh;
}

.review {
  margin-top: 1.3rem;
}

.review-link {
  display: block;
  color: #F2F2F2;
  text-decoration-line: underline;
  font-size: 0.9em;
  margin-bottom: 1vh;
  /* line-height: 0.5; */
}

p.review-link {
  text-decoration-line: none;
  /* margin-bottom: 0; */
}

.review-content {
  text-indent: 1.5rem;
  text-align: justify;
}

/* FilmList mobile */

.list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 5vh 0 2vh 0;
  overflow: visible;
  position: absolute;
  visibility: visible;
  /* max-height: 200vh; */
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: opacity 0.3s;
}

.list-items-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  margin: 0 auto 2vh auto;
  padding: 1vh 3vw;
  flex: 0 0 auto;
  flex-shrink: 0;
}

.card {
  border: none;
  width: 72vw;
  margin: 2vh 2.5vw 6vh 2.5vw;
  background: none;
  flex: 0 0 auto;
  flex-shrink: 0;
}

.card:last-of-type {
  margin-bottom: 1vh;
}

.card-click, .card-poster {
  /* border: 2px solid #F2F2F2; */
  width: 100%;
  height: auto;
}


.card-click:hover {
  opacity: 1;
}

.card-title {
  margin-top: 0.8vh;
  padding: 2%;
  text-align: center;
  color: #F2F2F2;
  font-size: 1rem;
  letter-spacing: 0.5px;
  /* text-decoration: underline; */
  text-transform: uppercase;
}

.card-title a {
  width: 100%;
}

/* About */
.about-page {
  padding: 0;
  width: 100%;
}

.page-hr {
  margin-bottom: 4vh;
}

.about-content {
  background-color: #212529;
  color: #F2F2F2;
  /* border-radius: 5px; */
  margin: 0;
  padding: 4vh 3%;
  text-align: justify;
  font-size: 0.9em;
}

.about-content div {
  color: #F2F2F2;
}

.about-section {
  font-family: 'Open Sans', sans-serif;
  padding: 0 3vw;
}

.about-section-1 {
  display: flex;
  flex-direction: column;
  margin-bottom: 20%;
}

.about-description {
  font-family: 'Open Sans', sans-serif;
  /* padding: 0 4vw; */
  /* text-indent: 1.5rem; */
  /* text-align: justify; */
  color: #212529;
}

.about-img {
  width: 100%;
  padding: 2vh 6vw 0 6vw;
  height: auto;
}

.media-links-container {
  margin-top: 4vh;
}

.media-link-group {
  display: flex;
  justify-content: space-evenly;
}

.media-links-header {
  text-align: center;
  margin-bottom: 3vh;
}

.media-link {
  opacity: 0.8;
  transition: opacity 0.3s;
}

.media-link:hover {
  opacity: 1;
}

.icon {
  width: 10vw;
  height: auto;
}

/* contact */

.contact-page {
  padding: 0;
  width: 86%;
  margin: 0 7vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* height: 100%; */
}

.contact-content {
  background-color: #212529;
  color: #F2F2F2;
  /* height: 100%; */
  margin: 0;
  padding: 4% 0 0 0;
  text-align: justify;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contact-header {
  text-align: center;
  width: 73%;
  margin: 2vh auto 4vh auto ;
}

.contact-form {
  width: 73%;
  height: 90%;
  margin: 0 auto 4% auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.form-control, .btn {
  font-size: 0.9rem;
  border-radius: 2px;
  color: #F2F2F2;
  background-color: #41474e;
  box-shadow: none !important;
}

.form-control:focus {
  border-color: #BFB4A2;
  background-color: #31373b;
  color: #F2F2F2
}

.textarea-group {
  height: 80%;
}

.textarea-group textarea {
  height: 100%;
}

/* loader transition */

.loader {
  position: absolute;
  visibility: visible;
  height: 100%;
  width: 100%;
  max-height: 200vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* opacity: 1;
  transition: opacity 0.2s */
}

.main-loader {
  z-index: 10;
  background-color: #dbcfba;
  opacity: 1;
  transition: opacity 0.2s;
}

.opacity-0 {
  opacity: 0;
  height: 0;
  width: 0;
}

.noshow {
  visibility: hidden !important;
  height: 0 !important;
  opacity: 0 !important;
}

.noshow .list-items-container, .noshow .details-content {
  display: none;
}


/* Lightbox centering rules */

.lightbox {
  align-content: center;
  align-items: center;
  height :100%;
  display: flex;
  flex-direction: column;
  position: fixed !important;
  top: 0 !important;
}

#lightboxOverlay {
  height: 100% !important;
  left: 0;
  position: fixed !important;
  top: 0;
  width: 100% !important;
  outline: none !important;
}

.lb-outerContainer {
   margin: auto;
}

.lb-image {
  margin: auto;
}

.lb-close {
   bottom: 100%;
   position: fixed;
   right: 3%;
   top: 30px;
 }


 
/* Medium devices (landscape mobile, tablets) */
@media only screen and (min-width: 640px){

  body {
    font-size: 16px;
  }

  p {
    font-size: 0.8rem;
    line-height: 1.5;
  }

  li {
    font-size: 0.8rem;
    margin-bottom: 2vh;
  }

  #bg, .bg-img {
    background-size: cover;
    background-position: center;
  }

  .main {
    flex-direction: row;
    height: 100%;
  }

  main {
    width: 75%;
    max-height: 100%;
    overflow: auto;
  }

  img {
    width: 100%;
    height: auto;
  }

  .blur:before, .blur-video {
    filter: blur(5px) brightness(85%);
  }

  ul {
    padding: 10%;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 0;
  }

  nav {
    position: static;
    width: 25%;
    height: 100%;
    padding-top: 0;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .inactive {
    opacity: 1;
  }

  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin: 5%;
  }

  #logo-image {
    max-width: 100%;
    height: auto;
  }

  #logo-image-mobile {
    display: none;
  }

  .nav-collapse {
    display: flex;
    /* padding-top: 7.2vh; */
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .nav-links {
    margin-left: 20%;
    width: 60%;
  }

  .nav-link a {
    font-size: 1rem;
  }

  .desktop-only {
    display: block;
  }

  .page-title {
    text-align: left;
    width: 80.5%;
    margin: 0 auto 1.5vh auto;
  }

  .title-hr {
    width: 79%;
    margin: 1vh 2vw 2vh 7.3vw;
  }

  .hr-less-margin {
    width: 79%;
    margin: 1vh 2vw 1vh 7.3vw;
  }

  .loader {
    position: absolute;
    visibility: visible;
    height: 100%;
    width: 100%;
    max-height: 200vh;
    display: flex;
    align-items: center;
    justify-content: center;
    /* opacity: 1;
    transition: opacity 0.2s */
  }

  .list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 7vh 2vw 6vh 2vw;
    width: 100%;
    
  }
  
  .list-items-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    /* justify-content: center; */
    width: 95%;
    margin: 0 auto 4vh auto;
    padding: 1vh 0;
  }
  
  .card {
    width: 25vw;
    border: none;
    margin: 2vh 6% 6vh 6%;
    background: none;
  }

  .card-click, .card-poster {
    width: 100%;
    height: auto;
  }


  .card-title {
    margin-top: 0.4vh;
    padding: 2%;
    text-align: center;
    color: #F2F2F2;
  }

  .back-link {
    width: initial;
    padding: 0 0 2vh 0;
    text-align: start;
    background: none !important;
  }

  .details-page {
    width: 90%;
    margin: 6vh auto;
    position: absolute;
    /* left: 5%; */
  }

  .colab-details {
    width: 48.5%;
  }

  .crew-container {
    margin-bottom: 0vh;
  }

  .details-section {
    margin-bottom: 1vh;
  }

  .details-section:last-of-type {
    margin-bottom: 2vh;
  }
  
  .details-section-header {
    margin-bottom: 0.6rem;
  }

  .cover-image {
    object-fit: cover;
    height: 80vh;
  }

  .details-content {
    padding: 2vh 2vw;
    background-color: #212529;
  }

  .details-content-text {
    width: 100%;
    text-align: justify;
    color: #F2F2F2;
  }
  
  .title {
    font-size: 2rem;
  }
  
  /* Gallery Configs */

  .gallery {
    width: 100%;
    padding: 2vh 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    /* margin-bottom: 3vh; */
    /* max-height: 50vh; */
  }
  
  .full-width, .half-width, .third-width, .full-width .thumb-link{
    width: 100%;
  }

  .half-width, .third-width {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .half-width .thumb-link{
    width: 48.5%;
  }

  .third-width .thumb-link{
    width: 32.5%;
  }

  .one-third {
    width: 32%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .two-thirds {
    width: 66.5%;
  }

  .percent-35 {
    width: 37%;
  }

  .percent-45 {
    width: 42%;
  }

  .percent-55 {
    width: 55%;
    flex-direction: row;
  }

  .percent-65 {
    width: 61%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .no-pad-top {
    padding: 0;
  }

  .thumb-link {
    margin-bottom: 0;
    /* width: 32%; */
    transition: opacity 0.15s;
  }
  
  .thumb-link:hover {
    opacity: 0.95;
  }

  .thumb-img {
    width: 100%;
  }

  .cast {
    width: 92%;
  }

  .crew {
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    justify-content: space-between;
    /* max-height: 70vh; */
  }

  /* .crew-column {
    width: 40%;
    margin-right: 5%;
  }

  .crew-column:last-of-type {
    padding-top: 1%;
  } */
  
  .crew-item {
    width: 100%;
    text-align: left;
  }

  
  .about-content {
    margin-bottom: 5vh;
    padding: 5% 2%;
  }
  
  .about-section-1 {
    margin-bottom: 10%;
  }

  .media-links-header {
    margin-bottom: 8%;
  }
  
  .icon {
    width: 6vw;
  }

  .contact-page {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* margin-top: 10%; */
  }

  .contact-content {
    margin: 5% auto;
    height: 60%;
    width: 80%;
    padding: 4% 2% 4% 2%;
    justify-content: center;
    /* overflow: scroll; */
  }

  .contact-form {
    width: 90%;
    /* margin: 0 auto 4vh auto; */
  }

  .contact-header {
    text-align: start;
    width: 90%;
    margin: 2vh auto 3vh auto;
  }

  .form-row {
    flex-wrap: nowrap;
  }

  /* .form-group {
    height: 50%;
  } */

  .form-control {
    font-size: 0.8rem !important;
  }

  /* textarea.form-control {
    height: 100%;
  } */

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

  body {
    font-size: 16px;
  }

  p {
    font-size: 0.8rem;
    line-height: 1.5;
  }

  li {
    font-size: 0.8rem;
    margin-bottom: 3vh;
  }

  #bg, .bg-img {
    background-size: cover;
    background-position: center;
  }

  .main {
    flex-direction: row;
    height: 100vh;
  }

  main {
    width: 80%;
    max-height: 100vh;
    overflow: auto;
  }

  ul {
    padding: 10%;
    flex-direction: column;
    justify-content: flex-start;
  }

  textarea {
    height: auto;
  }

  /* li.nav-link {
    margin-bottom: 4vh;
    font-size: 1.3rem;
  } */

  nav {
    position: static;
    width: 20%;
    height: 100%;
    padding-top: 0;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .inactive {
    opacity: 1;
  }

  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    margin: 15%;
  }

  #logo-image {
    max-width: 100%;
    height: auto;
  }

  #logo-image-mobile {
    display: none;
  }

  .nav-collapse {
    display: flex;
    padding-top: 7.2vh;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .nav-links {
    margin-left: 20%;
    width: 60%;
  }

  .list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 7vh 2vw 6vh 2vw;
    width: 100%;
    
  }
  
  .list-items-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    /* justify-content: center; */
    width: 95%;
    margin: 0 auto 4vh auto;
    padding: 1vh 3vw;
  }
  
  .card {
    width: 16.5vw;
    border: none;
    margin: 2vh 2.5vw 4vh 2.5vw;
    background: none;
  }

  .card-click, .card-poster {
    width: 100%;
    height: auto;
  }

  .details-page {
    left: 5%;
  }

  .details-div {
    display: flex;
    justify-content: space-between;
  }

  .details-margin {
    /* margin-bottom: 2rem; */
  }

  .summary-cast-container {
    width: 48.5%;
    /* margin-right: 3%; */
  }

  .summary-cast-container div {
    margin-bottom: 4%;
  }

  .summary-cast-container div:last-of-type {
    margin-bottom: 0;
  }

  .summary {
    width: 100%;
  }

  .crew-container {
    width: 48.5%;
    margin-bottom: 0;
  }


  .festivals-list {
    column-count: 2;
    column-gap: 3%;
    /* display: grid;
    grid-template-columns: 48.5% 48.5%; */
    justify-content: space-between;
    align-content: space-between;
    margin-bottom: 2vh;
  }

  .reviews-container {
    width: 100%;
    /* display: grid; */
    column-count: 2;
    column-gap: 3%;
    /* grid-template-columns: 48.5% 48.5%; */
    /* max-height: 80vh;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap; */
    justify-content: space-between;
    align-content: space-between;
  }

  .review {
    width: 100%;
    display: inline-block;
    margin-top: 0;
  }

  .review:first-of-type {
    margin-top: 0;
  }

  .review p {
    margin-top: 0.4rem;
  }

  .review:last-of-type p {
    margin-bottom: 0;
  }


  .about-page {
    padding: 7vh 2vw 6vh 2vw;
  }

  .about-content {
    margin: 0 7.3vw 6vh 7.3vw;
    width: 79.3%;
    padding: 4vh 1.5vw 2vh 1.5vw;
    /* min-height: 80vh; */
    /* background-color: #F2F2F2; */
    font-family: 'Open Sans', sans-serif;
    text-align: justify;
  }

  .about-title {
    width: 90.5%;
    margin-bottom: 0.4rem;
  }

  .about-hr {
    width: 90%;
    margin: 0 auto 2rem auto;
  }

  .about-section {
    /* display: flex; */
    margin-bottom: 5vh;
    padding: 0 0.5vh;
  }

  .about-section:last-of-type {
    margin-bottom: 0;
  }

  .about-section-1 {
    display: flex;
    flex-direction: row;
    margin: 0;
  }

  .about-description {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
    width: 48%;
    margin-right: 3%;
    text-align: justify;
  }

  .about-img {
    width: 100%;
    height: auto;
    padding: 0;
  }

  .icon {
    width: 2.5rem;
  }

  .media-links-header {
    text-align: start;
    margin-bottom: 8%;
  }

  .media-links-container {
    margin-top: 0;
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .media-link-group {
    /* width: 100%; */
    /* margin-top: 25%; */
    width: 70%;
    /* height: 20%; */
    flex-direction: row;
    justify-content: space-around;
  }

  .bios-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .bios-column {
    width: 48%;
    margin-right: 2%;
  }

  .bios-column:last-of-type {
    margin-right: 0;
  }

  .contact-page {
    margin: 0;
    justify-content: center;
    /* margin-top: 10%; */
  }

  .contact-content {
    margin: 5% auto;
    height: 70%;
    width: 60%;
    padding: 4% 2% 4% 2%;
    justify-content: center;
    /* overflow: scroll; */
  }

  .contact-form {
    width: 90%;
    /* margin: 0 auto 4vh auto; */
  }

  .contact-header {
    text-align: start;
    width: 90%;
    margin: 0 auto 3vh auto;
  }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}



/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
